// Copyright © 2021 Move Closer

import { Component, Vue, Prop } from 'vue-property-decorator'
import { DashmixIconName, DashmixTheme } from '@d24/modules'
import { IModal, ModalType } from '@movecloser/front-core'

import { Inject } from '@/shared/plugins/inversify'

import { ConfirmModalContent, ConfirmModalPayload } from './ConfirmModal.contracts'
import { AbstractModal } from '../Modal/AbstractModal/AbstractModal'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'ConfirmModal',
  template: `
    <D24ModalInner :noControls="payload.noControls" @close="modalConnector.close()">
      <template v-slot:header>
        <h5 class="modal-title">
          {{ $t(content.header) }}
        </h5>
      </template>

      <p class="mb-1">
        {{ $t(content.contentText) }}<strong>{{ content.contentTitle }}</strong>?
      </p>

      <template v-slot:footer>
        <D24Button v-if="!payload.noControls"
                   :label="$t('atoms.cancel')" @click="modalConnector.close()" :theme="themes.Light" />
        <D24Button :theme="content.theme ? content.theme : 'info'" :label="$t(content.buttonLabel)"
                   @click="payload.onConfirm" />
      </template>
    </D24ModalInner>
  `
})
export class ConfirmModal extends AbstractModal {
  @Prop({ type: Object, required: true })
  public payload!: ConfirmModalPayload

  @Inject(ModalType)
  protected modalConnector!: IModal

  public content: ConfirmModalContent = this.payload.content
  public readonly icons = DashmixIconName
  public readonly themes = DashmixTheme
}
