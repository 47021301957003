// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { ExtendedMDE } from 'simplemde'
import { ModuleDriver, SizeMap } from '@d24/modules'

import { findTokenAtCurrentLine, replaceTokenAtCurrentLine } from '@component/MarkdownEditor/helpers/line-parser'
import { Link } from '@/shared/contracts/data'
import { Modals } from '@/config/modals'

import { Shortcut } from '../MarkdownEditor.contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export const linkShortcut: Shortcut = {
  action: (editor: ExtendedMDE) => {
    const onSelection = (data: Link) => {
      if (!data.url) {
        return
      }

      replaceTokenAtCurrentLine(editor, ModuleDriver.Paragraph, { text: `[${data.name || ''}](${data.url})` })
    }

    if (!editor.codemirror.getSelection()) {
      editor.options.modalConnector.open(Modals.Confirm, {
        onClose: () => editor.options.modalConnector.close(),
        onConfirm: () => editor.options.modalConnector.close(),
        content: {
          buttonLabel: 'OK',
          contentText: 'Nie zaznaczono tekstu do zamiany na link',
          header: 'Brak zaznaczenia'
        }
      })
      return
    }
    const token: AnyObject | null = findTokenAtCurrentLine(editor, ModuleDriver.Paragraph)

    let selected: Link | undefined

    // Method to get url and name form paragraph text
    if (token && Object.prototype.hasOwnProperty.call(token, 'text')) {
      const match = token.text.match(/\[(.*?)]\((.*?)\)/)
      const name = match?.[1] ?? token.text
      const url = match?.[2] ?? ''

      selected = {
        name,
        url
      }
    }

    editor.options.modalConnector.open(Modals.PickLink, {
      onClose: () => editor.options.modalConnector.close(),
      selected: selected,
      onSelection: onSelection,
      config: { name: editor.codemirror.getSelection() }
    }, { size: SizeMap.Large })
  },
  className: 'fas fa-link',
  name: 'create-link',
  title: 'Wstaw link'
}
