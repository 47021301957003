




















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixSelectItem, RelatedType } from '@d24/modules'

import { Identifier } from '@/shared/contracts/data'

import { Item } from '@component/TreeViewer'

import { ContentRepositoryType, FormMode, IContentRepository } from '../contracts'
import { ContentLinksType } from '../maps/smart'
import { NodeSelect } from './NodeSelect.vue'
import { Inject } from '@plugin/inversify'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<ContentPickerPage>({
  name: 'ContentPickerPage',
  components: { NodeSelect },
  created (): void {
    this.site = this.siteResolver.getSite()?.id ?? null
    this.sites = [...this.siteResolver.getDictionary()]
      .map(site => {
        return {
          label: site.domain,
          value: site.id
        }
      })
  },

  mounted () {
    // TODO fix - see below
    (document.querySelector('.modal-content') as HTMLDivElement).style.overflow = 'visible'
  }
})
export class ContentPickerPage extends Vue {
  @Prop({ type: Number, required: false })
  public selectedId?: Identifier

  @Prop({ type: Function, required: true })
  public onSelect!: (selected: Identifier, related: ContentLinksType | null) => void

  @Inject(ContentRepositoryType)
  private contentRepository!: IContentRepository

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public formMode: FormMode = FormMode.Picker
  public isLoading: boolean = false
  public isInternalLoading: boolean = true
  public nodes: Item[] = []
  public site: Identifier | null = null
  public sites: DashmixSelectItem[] = []

  public async selectNodes (selectedItemsList: any[]): Promise<void> {
    if (this.isInternalLoading) {
      return
    }

    this.nodes = selectedItemsList

    const node = selectedItemsList.slice(-1).pop()

    if (node) {
      this.onSelect(Number(node.value), RelatedType.Content)
    }
  }

  protected changeSite (site: Identifier): void {
    this.site = site
  }

  @Watch('selectedId')
  private updateSelected (selectedId: Identifier) {
    if (selectedId) {
      this.nodes = [{
        value: selectedId,
        label: ''
      }]
    }
  }
}

export default ContentPickerPage
